<script>
  import { onMount } from 'svelte';
  export let title = "Default title";
  export let text;
  export let imgSrc;
  export let imgAlt;
  export let videoSrc;
  export let dividerAbove = false;
  export let dividerBelow = false;

  export let componentData;
  const BASE_URL = import.meta.env.PUBLIC_BASE_URL;
const BASE_SELF_URL = import.meta.env.PUBLIC_BASE_SELF_URL;


  if(componentData){
    title = componentData.TitleAsTextBlockWithMedia ? componentData.TitleAsTextBlockWithMedia : '';
    text = componentData.Text
    imgSrc = BASE_URL + (componentData?.Image?.data?.attributes?.url ?? '')
    if (imgSrc.endsWith('mp4')) {
      imgSrc = false
      videoSrc = BASE_URL + (componentData?.Image?.data?.attributes?.url ?? '')
    }
  }




  let video;
  let videoContainer;

  onMount(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            video.play();
          } else {
            video.pause();
          }
        });
      },
      { threshold: 0.5 } 
    );

    observer.observe(videoContainer);

    return () => {
      observer.disconnect(); 
    };
  });
</script>

<style lang="scss">
  .block__text{
    max-width: 540px;

    h2 { margin-bottom: 40px; }
  }
  .block__media {
    img,
    video{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and ( max-width: 750px ) {
      order: -1;
    }
  }
</style>
  
<section class="section"
  class:divider-above={dividerAbove}
  class:divider-below={dividerBelow}>
  <div class="wrapper">
    <div class="grid grid--2 align-bottom">
      <div class="block__text">
        <h2>{title}</h2>
        {#if text}
          {@html text}
        {/if}
      </div>

      <div bind:this={videoContainer} class="block__media">
        {#if imgSrc}
          <img width="1920" height="1080" src={imgSrc} alt={imgAlt} loading="lazy">
        {:else}
          <video width="1920" height="1080" bind:this={video} muted autoplay loop>
            <source src={videoSrc} type="video/mp4">
            Your browser does not support the video tag.
          </video>
        {/if}
      </div>
    </div>
  </div>
</section>